import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Interviews: best practices",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "interviews-best-practices",
  "draft": false,
  "meta_title": "Interviews: best practices"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In the first part of this series, `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/interviews-the-breakdown/"
      }}>{`Interviews: The Breakdown`}</a>{`, we discussed the types of interviews and the basics of preparing for each type. Interviews are new to the transitioning veteran and are different based on the industry and type of business. Let's explore some best practices once you introduce yourself to the interviewer.`}</p>
    <p><strong parentName="p">{`High-Level Best Practices`}</strong></p>
    <p>{`At the core, the two biggest things that you can do to improve your chances of success are to prepare extensively and to understand that the interview is not about you – it is about determining whether you are a good fit for the firm and its culture. In terms of preparation, it’s best to identify a list of potential questions much like those from the `}<a parentName="p" {...{
        "href": "http://www.vpul.upenn.edu/careerservices/interviewingadvice/intervwksheet.php"
      }}>{`University of Pennsylvania Career Services`}</a>{` and then have a friend ask them to you while videotaping your responses so that you can review your tone, enunciation, and non-verbals. The more deliberately that you practice your responses to these questions the more that you will be able to refine your answers and determine the best way to present yourself as a candidate. You’d be amazed at how quickly you’ll make the necessary adjustments when you watch the video recording of your practice interview. If it’s possible, there’s also a value-add in going to an actual job interview for a position that you’re not particularly interested in to help further refine your skills and build up your confidence; this will help you get some solid real-world experience under your belt before undertaking the interview at the firm that you’re most interested in.`}</p>
    <p>{`The second high-level key to success is to understand that the recruiter and hiring managers are looking for someone who fits well with their team and their organization. Make certain to do your research beforehand to gain as much of an understanding as you can about the attitudes, dress, and atmosphere of your target companies. Most interviews also have a predictable flow: they follow a set script, engage in small talk to kick things off, and then get into the nitty-gritty of job-related details. Recruiters or hiring managers want someone who is going to fit well into their culture, business, and goals but be sure that culture is someplace you also see YOURSELF fitting into. Ask questions and when you are sitting down with the hiring manager or recruiter, build rapport. Take advantage of that small talk, no matter how awkward it feels. It’s very good to come off as confident, however, you need to make certain that you don’t overdo it and portray yourself as brash, arrogant, or egotistical. People generally like dealing with people who are similar to them yet have a strong sense of who they are, the story behind what brought them to this point, and the ability to effectively articulate their story. Knowing your "why" is often the defining moment in successful communication and relationship building with employers, clients, and even friends and family. We'll dive into storytelling and developing your "why" in our final series on interviewing next week!`}</p>
    <p>{`~The Purepost Team`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      